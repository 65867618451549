import { Box } from "@chakra-ui/react";
import type { ComponentProps } from "react";
import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar/Navbar";

interface PublicNavbarLayout {
  isDark: boolean;
}

const PublicNavbarLayout = ({
  isDark,
  children,
}: ComponentProps<any>) => {
  return (
    <Box>
      <Navbar isDark={isDark} />
      <Box flexGrow={1}>{children}</Box>
      <Footer />
    </Box>
  );
};

export default PublicNavbarLayout;
