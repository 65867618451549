import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  HStack,
  Text,
  Image,
  IconButton,
  VStack,
  StackDivider,
  Avatar,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/hooks";
import { HamburgerIcon } from "@chakra-ui/icons";
import type { ComponentProps } from "react";
import React from "react";
import Link from "next/link";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";

import { getPaths } from "lib/utils/paths";
import useTranslationConstants from "lib/utils/constants";
import { useUser } from "lib/contexts/useUser";
import { useLocales } from "src/contexts/LocalesContext";


interface INavLinkProps extends ComponentProps<any> {
  url: string;
  onClick?: () => void;
  locale?: string;
}

const NavLink = ({ url, locale, onClick, children, ...rest }: INavLinkProps) => (
  <Link passHref href={url} locale={locale}>
    {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
    <Text
      fontWeight="bold"
      textTransform="uppercase"
      letterSpacing={1.6}
      onClick={onClick}
      cursor="pointer"
      {...rest}
    >
      {children}
    </Text>
  </Link>
);


const MobileNavbar = () => {
  const t = useTranslations("components.navbar");
  const { locales } = useLocales();
  const { locale, route } = useRouter();
  const { LINKS } = useTranslationConstants();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef: any = React.useRef();
  const { athlete } = useUser();

  return (
    <>
      <HStack
        h="80px"
        w="full"
        pos="fixed"
        top={0}
        pl={8}
        pr={4}
        justify="space-between"
        className="glass"
        zIndex={100}
      >
        <Link href={getPaths.index.home()} locale={locale}>
          <Image
            alt={t("logoAlt")}
            src={LINKS.images.miniLogo}
            height={8}
          />
        </Link>

        <IconButton
          colorScheme="blackAlpha"
          size="lg"
          ref={btnRef}
          onClick={onOpen}
          aria-label={t("openMenu")}
          icon={<HamburgerIcon />}
          variant="link"
        />
      </HStack>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton p="8" />

          <DrawerHeader>
            <Link href={getPaths.index.home()} locale={locale}>
              <Image
                cursor="pointer"
                alt={t("logoAlt")}
                src={LINKS.images.nameLogo}
                h="12"
                pt="16px"
                pb="1px"
                objectFit="contain"
                objectPosition="center"
                onClick={onClose}
              />
            </Link>
          </DrawerHeader>

          <DrawerBody>
            <VStack
              py={5}
              alignItems="flex-start"
              spacing={8}
              divider={<StackDivider borderColor="gray.700" />}
            >
              <VStack alignItems="flex-start" spacing={5}>
                <NavLink
                  url={getPaths.index.sections.sports.url()}
                  onClick={onClose}
                >
                  {t("sports")}
                </NavLink>
                <NavLink
                  url={getPaths.index.sections.coaches.url()}
                  onClick={onClose}
                >
                  {t("coaches")}
                </NavLink>
                <NavLink
                  url={getPaths.index.sections.coaches.url()}
                  onClick={onClose}
                >
                  {t("gyms")}
                </NavLink>
                <NavLink
                  url={getPaths.index.sections.healthProfessionals.url()}
                  onClick={onClose}
                >
                  {t("healthProfessionals")}
                </NavLink>
                <NavLink
                  url={getPaths.index.sections.mission.url()}
                  onClick={onClose}
                >
                  {t("mission")}
                </NavLink>
                <NavLink
                  url={getPaths.index.sections.reviews.url()}
                  onClick={onClose}
                >
                  {t("reviews")}
                </NavLink>
              </VStack>
              <NavLink url={athlete ? getPaths.athlete.index() : getPaths.login()}>
                <Avatar
                  bg="lightGray"
                  size="xs"
                  src={athlete?.avatar}
                  mr="4"
                />
                {athlete ? t("personalArea") : t("login")}
              </NavLink>
              <HStack spacing={5}>
                {locales?.map(({ code: loc }, i) => (
                  <React.Fragment key={loc}>
                    {i > 0 && (
                      <Text fontWeight="medium" variant="muted">
                        /
                      </Text>
                    )}
                    <NavLink
                      key={locale}
                      href={route}
                      locale={loc}
                      url={route}
                      fontWeight="medium"
                      variant={locale === loc ? undefined : "muted"}
                    >
                      {loc.toUpperCase()}
                    </NavLink>
                  </React.Fragment>
                ))}
              </HStack>
            </VStack>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileNavbar;
