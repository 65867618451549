import Icon from "@chakra-ui/icon";
import {
  Box,
  HStack,
  Image,
  Text,
  Link as ChakraLink,
  Stack,
} from "@chakra-ui/react";
import Link from "next/link";
import { useTranslations } from "next-intl";
import type { ComponentProps } from "react";
import React from "react";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import LayoutContainer from "./LayoutContainer";
import { getPaths } from "lib/utils/paths";
import useTranslationConstants from "lib/utils/constants";

interface INavLinkProps extends ComponentProps<any> {
  url: string | Record<string, any>;
  locale?: string;
}

const NavLink = ({ url, locale, children, ...rest }: INavLinkProps) => (
  <Link passHref href={url} locale={locale}>
    {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
    <Text
      fontWeight="bold"
      textAlign={["center", "center", "left", "left"]}
      textTransform="uppercase"
      letterSpacing={4}
      pb="2"
      cursor="pointer"
      {...rest}
    >
      {children}
    </Text>
  </Link>
);

const Footer = () => {
  const t = useTranslations("components.navbar");
  const { LINKS } = useTranslationConstants();

  return (
    <LayoutContainer>
      <Box
        display="flex"
        flexDirection={["column", "column", "column", "row"]}
        w="full"
        borderColor="textGray"
        borderTopWidth={0.5}
        gap={[8, 8, 12, 12]}
        pt={12}
        pb={8}
      >
        <Box
          flex={1}
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          gap="4"
          justifyContent={["center", "center", "center", "flex-start"]}
        >
          <Image
            h="100%"
            maxH="120px"
            alt="myHeart logo"
            src={LINKS.images.bigLogo}
            filter={"grayscale(100%)"}
          />
          <Text
            mt="2"
            fontSize="2xs"
            fontWeight="bold"
            textAlign="center"
          >
            {t("createdByBeapt")}
          </Text>
          <Image
            w="120px"
            alt="beapt logo"
            src={LINKS.images.beaptOldLogo}
          />
          <Image
            w="80%"
            maxW="200px"
            alt="strava logo"
            src={LINKS.images.poweredByStrava}
            filter={"grayscale(100%)"}
          />
        </Box>
        <Box
          flex={2}
          display="flex"
          flexDirection="column"
          alignItems={["center", "center", "center", "flex-start"]}
        >
          <NavLink url={getPaths.index.sections.sports.url()}>
            {t("sports")}
          </NavLink>
          <NavLink url={getPaths.index.sections.coaches.url()}>
            {t("coaches")}
          </NavLink>
          <NavLink url={getPaths.index.sections.coaches.url()}>
            {t("gyms")}
          </NavLink>
          <NavLink url={getPaths.index.sections.healthProfessionals.url()}>
            {t("healthProfessionals")}
          </NavLink>
        </Box>
        <Box
          flex={2}
          display="flex"
          flexDirection="column"
          alignItems={["center", "center", "center", "flex-start"]}
        >
          <NavLink url={getPaths.index.sections.mission.url()}>
            {t("mission")}
          </NavLink>
          <NavLink url={getPaths.index.sections.reviews.url()}>
            {t("reviews")}
          </NavLink>
          <NavLink url={getPaths.register.athlete.step(1)}>
            {t("register")}
          </NavLink>
          <ChakraLink
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            href={LINKS.MyHeartEmailMailTo}
          >
            <Text
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing={1.6}
              pb="2"
              cursor="pointer"
            >
              {t("contacts")}
            </Text>
          </ChakraLink>
          <NavLink url={getPaths.termsOfService()}>
            {t("termsAndConditions")}
          </NavLink>
        </Box>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems={["center", "center", "center", "flex-end"]}
          pb="4"
        >
          <Text
            fontWeight="bold"
            textTransform="uppercase"
            letterSpacing={1.6}
            pb="2"
            cursor="pointer"
          >
            {t("followUs")}
          </Text>
          <HStack>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={LINKS.pauloPiresFacebook}
            >
              <Box
                bg="textGray"
                display="flex"
                borderRadius="100%"
                p="2"
              >
                <Icon
                  as={AiFillFacebook}
                  color="white"
                  fontSize="2xl"
                  transition="all ease 0.2s"
                />
              </Box>
            </Link>
            <Link
              target={"_blank"}
              rel="noopener noreferrer"
              href={LINKS.pauloPiresInstagram}
            >
              <Box
                bg="textGray"
                display="flex"
                borderRadius="100%"
                p="2"
              >
                <Icon
                  as={AiFillInstagram}
                  color="white"
                  fontSize="2xl"
                  transition="all ease 0.2s"
                />
              </Box>
            </Link>
          </HStack>
        </Box>
      </Box>
      <Stack
        direction={["column", "row", "row", "row"]}
        pb={4}
        w="full"
        align="center"
        justify="center"
        gap={[6, 6, 6, 12]}
      >
        <Image
          h={"30px"}
          alt="redlight logo"
          src={LINKS.images.redlight}
          // eslint-disable-next-line i18next/no-literal-string
          onClick={() => window.open("https://redlight.dev/", "_blank")}
          cursor="pointer"
        />
        <Image
          h={"50px"}
          alt="sparrow logo"
          src={LINKS.images.sparrow}
          // eslint-disable-next-line i18next/no-literal-string
          onClick={() => window.open("https://sparrowcreativesolutions.com/", "_blank")}
          cursor="pointer"
        />
      </Stack>
    </LayoutContainer>
  );
};

export default Footer;
