import type { ContainerProps } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react";
import React from "react";

const LayoutContainer = ({ children, ...props }: ContainerProps) => (
  <Container w="full" maxW="1400px" py="1.5rem" px={[5, 5, 5, 8]} {...props}>
    {children}
  </Container>
);

export default LayoutContainer;
