import React from "react";
import { useMediaQuery } from "@chakra-ui/media-query";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";

export interface INavbarProps {
  isDark?: boolean;
}

const Navbar = (props: INavbarProps) => {
  const [showDesktopNavbar] = useMediaQuery("(min-width: 1001px)");
  const Component = showDesktopNavbar ? DesktopNavbar : MobileNavbar;
  return <Component {...props} />;
};

export default Navbar;
