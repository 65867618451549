import { Box, HStack, Text } from "@chakra-ui/layout";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import type { ComponentProps } from "react";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { Image } from "@chakra-ui/image";
import { Avatar, VStack } from "@chakra-ui/react";
import { getPaths } from "lib/utils/paths";
import useTranslationConstants from "lib/utils/constants";
import { useUser } from "lib/contexts/useUser";
import LayoutContainer from "../LayoutContainer";
import { useLocales } from "src/contexts/LocalesContext";

interface INavbarProps {
  isDark?: boolean;
}

interface INavLinkProps extends ComponentProps<any> {
  url: string | Record<string, any>;
  locale?: string;
  caps?: boolean;
}

const NavLink = ({ url, locale, caps, children, ...rest }: INavLinkProps) => (
  <Link passHref href={url} locale={locale}>
    {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
    <Text
      fontWeight="bold"
      textTransform={caps ? "uppercase" : undefined}
      letterSpacing={1.6}
      cursor="pointer"
      {...rest}
    >
      {children}
    </Text>
  </Link>
);


const DesktopNavbar = ({ isDark }: INavbarProps) => {
  const t = useTranslations("components.navbar");
  const { locales } = useLocales();
  const { locale, route, query } = useRouter();
  const { LINKS } = useTranslationConstants();
  const [isTransparent, setTransparent] = useState(isDark ? false : true);
  const { athlete } = useUser();
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isDark) {
      window.addEventListener("scroll", listenToScroll);
      return () => window.removeEventListener("scroll", listenToScroll);
    }
  }, []);

  const listenToScroll = () => {
    const windowScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (windowScroll > 240) {
      setTransparent(false);
      return;
    }

    if (windowScroll <= 240) {
      setTransparent(true);
      return;
    }
  };

  return (
    <VStack
      w="full"
      pos="fixed"
      top={0}
      left={0}
      zIndex={100}
    >
      <Box
        w="full"
        pos="absolute"
        transition="height ease 0.2s"
        h={isTransparent ? 0 : "100px"}
        className="glass"
      />

      <HStack
        transition="transparency ease 0.2s"
        position="absolute"
        justify="center"
        pt={isTransparent ? 12 : 4}
        opacity={isTransparent ? 0 : 1}
        zIndex={120}
      >
        <Link href={getPaths.index.home()} locale={locale}>
          <Image
            alt={t("logoAlt")}
            src={LINKS.images.miniLogo}
            width={10}
            cursor="pointer"
          />
        </Link>
      </HStack>

      <LayoutContainer
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        px={12}
        pt={isTransparent ? 12 : 4}
        pb={0}
        zIndex={110}
      >
        <NavLink
          url={athlete
            ? getPaths.athlete.index()
            : getPaths.login()}
          variant="muted"
          fontWeight="medium"
          fontSize="xl"
        >
          <Avatar
            bg="lightGray"
            size="sm"
            src={athlete?.avatar}
            mr="8"
          />
          {athlete ? t("personalArea") : t("login")}
        </NavLink>

        <HStack spacing={5}>
          {locales?.map(({ code: loc }, i) => (
            <React.Fragment key={loc}>
              {i > 0 && (
                <Text fontSize="md" variant="muted">
                  /
                </Text>
              )}
              <NavLink
                caps
                locale={loc}
                url={{ pathname: `/${loc}${route}`, query }}
                fontSize="md"
                fontWeight="medium"
                variant={locale === loc ? undefined : "muted"}
              >
                {loc}
              </NavLink>
            </React.Fragment>
          ))}
        </HStack>
      </LayoutContainer>

      <LayoutContainer
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        px={12}
        py={isTransparent ? 6 : 2}
        zIndex={110}
      >
        <NavLink caps url={getPaths.index.sections.sports.url()}>
          {t("sports")}
        </NavLink>
        <Text fontWeight="bold">|</Text>
        <NavLink caps url={getPaths.index.sections.coaches.url()}>
          {t("coaches")}
        </NavLink>
        <Text fontWeight="bold">|</Text>
        <NavLink caps url={getPaths.index.sections.coaches.url()}>
          {t("gyms")}
        </NavLink>
        <Text fontWeight="bold">|</Text>
        <NavLink caps url={getPaths.index.sections.healthProfessionals.url()}>
          {t("healthProfessionals")}
        </NavLink>
        <Text fontWeight="bold">|</Text>
        <NavLink caps url={getPaths.index.sections.mission.url()}>
          {t("mission")}
        </NavLink>
        <Text fontWeight="bold">|</Text>
        <NavLink caps url={getPaths.index.sections.reviews.url()}>
          {t("reviews")}
        </NavLink>
      </LayoutContainer>
    </VStack>
  );
};

export default DesktopNavbar;
